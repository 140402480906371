import Footer from "../../components/Footer";
import Topbar from "../../components/Topbar";
import Term from "../Terms";

const Home = () => {
  return (
    <>
      <Topbar />
      <Term />
      {/* <Header />
      <Hero />
      <WhyChooseSection />
      <DownloadSection /> */}
      <Footer />
    </>
  );
};

export default Home;
