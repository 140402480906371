import React from "react";

const Topbar = (props) => {
  return (
    <div className="topbar bg-red-gradient">
      <div className="container d-flex justify-content-between">
        <div className="topbar-left ">
          <ul className="d-flex">
            <li className="ps-0">
              <i className="fas fa-solid fa-phone text-white fa-lg fa-rotate-90" />{" "}
              +91 6262306306
            </li>
            <li>
              <i className="fas fa-solid fa-envelope text-white fa-lg" />{" "}
              care@aiqahealth.com
            </li>
          </ul>
        </div>
        {/* <div className="topbar-right ">
          <ul className="d-flex">
            <li>
              <a
                href="https://fb.watch/aAJb6ruaIo/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook text-white fa-lg" />
              </a>
            </li>
            <li className="pe-0">
              <a
                href="https://www.linkedin.com/posts/doctoroncallindia_commoncold-cough-covidguidelines-activity-6888345117518913536-Js9h"
                target="_blank"
                rel="noreferrer"
              >
                <i href="" className="fab fa-linkedin text-white fa-lg" />
              </a>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default Topbar;
