import { AppBar, Box, Container, Toolbar, Typography } from "@mui/material";
import React from "react";
import Logo from "../../assets/images/aiqadoc_app2.png";

import "./style.css";

const index = () => {
  return (
    <div style={{ height: "100%", backgroundColor: "white" }} id="terms">
      {" "}
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          style={{
            backgroundColor: "white",
            paddingLeft: "50px",
            marginTop: "40px",
          }}
          elevation={0}
        >
          <Toolbar>
            <img src={Logo} width={80} height={70} alt="Logo" />
          </Toolbar>
        </AppBar>
      </Box>
      <Container maxWidth="lg" style={{ paddingTop: "60px" }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            textAlign: "center",
            paddingBottom: "60px",
            fontFamily: "inherit",
          }}
        >
          TERMS AND CONDITIONS FOR GENERAL PHYSICIANS AND PRACTITIONERS
        </Typography>
        <Typography
          variant="h6"
          gutterBottom
          className="heading"
          //   style={{ paddingBottom: '30px', fontFamily: 'inherit' }}
        >
          1.&nbsp;&nbsp;INTRODUCTION
        </Typography>
        <Typography gutterBottom className="paragraphAlignment">
          <span className="spanSpace">1.1</span>
          The{" "}
          <a href="https://doctoroncall.org/" target="_blank">
            https://doctoroncall.org/
          </a>
          &nbsp; and&nbsp;
          <a href="https://aiqahealth.com/" target="_blank">
            https://aiqahealth.com/
          </a>{" "}
          &nbsp; website, mobile applications or other present or future
          electronic modes (collectively as “Website” or app named ‘aiqadoc’) is
          an internet based as well as in person medical teleconsultation
          platform / service, through website, e-clinics as well as clinics,
          owned and operated by{" "}
          <span className="spanSpace">Doctor on Call Private Limited </span>
          (hereinafter referred to as “we”, “us”, “DOC” or the “Company”), which
          is a private limited company incorporated under the{" "}
          <span className="spanSpace">Companies Act, 1956</span> with{" "}
          <span className="spanSpace">CIN U85191DL2007PTC159017</span> and
          having its registered office at{" "}
          <span className="spanSpace">
            [A-10/6 Vasant Vihar, South Delhi, New Delhi – 110057]
          </span>{" "}
          and corporate office at{" "}
          <span className="spanSpace">
            [5th floor, SAS Tower, Sector 38, Gurugram 122001 Haryana, India].
          </span>
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <span className="spanSpace">1.2</span>
          Through the Website, we are connecting the medical professionals, and
          practitioners (hereinafter referred to as “Practitioner” or “your” or
          “you”) with the clients / patients who wish to avail the healthcare
          services limited to primary healthcare services (“Services”). The
          person accessing the Website hereinafter referred to as “User(s)”.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <span className="spanSpace">1.3</span>
          These terms and conditions (“Terms”) and the privacy policy (“Privacy
          Policy”) are an electronic record under the provisions of the
          Information Technology Act, 2000 and relevant rules, regulations,
          bylaws framed there under (as applicable or amended from time to time)
          including the notifications, circulars, guidance notes etc. issued
          therein and governed by and under the Applicable Laws. These Terms are
          published in accordance with the provisions of Rule 3 (1) of the
          Information Technology (Intermediaries guidelines) Rules, 2011 that
          require publishing the rules and regulations, privacy policy and user
          agreements for access or usages of the Website.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <span className="spanSpace">1.4</span>
          These Terms along with the Privacy Policy, as set out at the website
          of DOC, together constitute the agreement between a Practitioner and
          the Company, governing usage of the Website by a Practitioner (“
          <strong>Agreement</strong>”) and are legally binding.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <span className="spanSpace">1.5</span>
          Use of the Website is offered to the Practitioner conditioned on
          acceptance without modification of all the terms, conditions and
          notices contained in the Agreement, as may be posted on the Website
          from time to time. For the removal of doubts, it is clarified that use
          of the Website by the Practitioner constitutes an acknowledgement and
          acceptance by the Practitioner of this Agreement.{" "}
          <strong>
            If the Practitioner does not agree with the Terms, or any part of
            such terms, conditions and notices, the Practitioner must not use
            the Website.
          </strong>
        </Typography>
        <Typography
          variant="h6"
          gutterBottom
          className="heading"
          //   style={{ paddingBottom: '30px', fontFamily: 'inherit' }}
        >
          2.&nbsp;&nbsp;DEFINITIONS
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          In this Agreement, unless the context or meaning otherwise requires,
          the following words and expressions as used herein shall have the
          following meanings:
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>“Applicable Law”</strong> includes all applicable national,
          foreign, provincial, local or other law including all applicable
          provisions of all: (a) constitutions, decrees, treaties, statutes,
          enactments, laws (including the common law), codes, notifications,
          rules, regulations policies, guidelines, circulars, directions,
          directives, ordinances or orders, decisions, injunctions, judgments,
          awards and decrees of or agreements of any Governmental Authority,
          statutory authority, court, tribunal having jurisdiction over such
          Party, including but not limited to, Information Technology Act, 2000
          (as amended from time to time), Information Technology (Reasonable
          Security Practices and Procedures and Sensitive Personal Information)
          Rules, 2011, Information Technology (Intermediary Guidelines and
          Digital Media Ethics Code) Rules, 2021 and Telemedicine Guidelines
          part of the Indian Medical Council (Professional Conduct, Etiquette
          and Ethics Regulation, 2002); (b) Governmental approvals; and (c)
          policies of the Company including but not limited to the Privacy
          Policy;
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>“Business Day”</strong> shall mean a day which is not a
          Saturday, Sunday or a public holiday in [New Delhi], India;
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>“Practitioner”</strong> shall mean any medical practitioner
          who is practicing in allopathy, homeopathy, unani, ayurveda or any
          other recognized healthcare profession and is a member of the relevant
          accredited Medical Council of India/State Medical Council; and
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>“User”</strong> shall mean any natural or legal person, who is
          competent to enter into a contract as per the Indian Contract Act,
          1872, who uses the Website, with or without registration, to explore
          and / or avail the Services offered, for himself or herself and/or for
          any other person.
        </Typography>
        <Typography variant="h6" gutterBottom className="heading">
          3.&nbsp;&nbsp;MODIFICATIONS OF TERMS
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          DOC reserves the right to add, modify, amend, cancel, limit, vary or
          change the Terms or the terms, conditions, and notices, either wholly,
          or in part, contained herein, at any point in time, under which the
          Services are offered through the Website, including but not limited to
          the changes in the charges for the Services provided through the
          Website, right and access provided on the Website to the Practitioner
          / User. Further, DOC reserves the right to withdraw, suspend, alter,
          modify, change, or vary the Terms of the Website, at its sole
          discretion, without any prior notice or intimation required. All
          modifications/amendments to the Agreement will be posted on the
          Website and will become effective immediately upon such posting on the
          Website. Practitioner’s continued use of the Website after such change
          or modification shall be deemed to be your agreement to the
          revised/modified Terms. The Practitioner shall be responsible for
          regularly reviewing these terms and conditions.
        </Typography>
        <Typography variant="h6" gutterBottom className="heading">
          4.&nbsp;&nbsp;PRIVACY POLICY
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          The Practitioner hereby consents, expresses and agrees that he has
          read and fully understands the Privacy Policy of DOC in respect of the
          Website and the Practitioner further consents that the terms and
          contents of such Privacy Policy are acceptable to him / her.
        </Typography>
        <Typography variant="h6" gutterBottom className="heading">
          5.&nbsp;&nbsp;CONDITIONS OF USE
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          The Practitioner must be 18 (eighteen) years of age or older to
          register, use the Services, or visit or use the Website in any manner.
          By registering, visiting and using the Website or accepting these
          Terms, you represent and warrant to the DOC that you are 18 (eighteen)
          years of age or older, and that you have the right, authority and
          capacity to use the Website and the Services available through the
          Website and agree to and abide by these Terms. That, by accessing and
          using the Website, Practitioner agrees to provide his / her data to
          the DOC, for the verification and record purposes. DOC reserves the
          absolute right to permit or deny the access to the Website to any
          Practitioner / User, without having to provide any justification or
          reasoning thereof.
        </Typography>
        <Typography variant="h6" gutterBottom className="heading">
          6.&nbsp;&nbsp;TERMS OF USE BY THE PRACTITIONER
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          The Practitioner expressly understand, acknowledge and agree to the
          following set forth herein below
        </Typography>
        <div style={{ paddingLeft: "30px" }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="paragraphAlignment"
          >
            <strong>(i)</strong> The Practitioner may use the Services solely to
            provide medical consultation in accordance with these Terms and
            Applicable Laws.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="paragraphAlignment"
          >
            <strong>(ii)</strong> The Practitioner shall at all times, act and
            provide his/her services to the Users in the capacity of an
            independent medical practitioner and shall have complete control
            over the judgement, analysis, prescription, diagnosis, and treatment
            of the Users. The Practitioner understands and acknowledges that DOC
            does not practice medicine and does not provide medical
            consultation, nor does DOC exercise any direct supervision or
            control over the Practitioner’s treatment, prescription, and
            management plan of any individual User. DOC merely provides a
            platform and infrastructure to enable the Practitioner to connect
            with the Users.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="paragraphAlignment"
          >
            <strong>(iii)</strong> The Practitioner will advise any course of
            treatment or prescribe any drugs or medical preparations and
            diagnostic tests for Users or express any medical opinion only when
            to do so is necessary in his / her sole professional judgment. DOC
            shall not be responsible for any medical advice given by the
            Practitioner to the User.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="paragraphAlignment"
          >
            <strong>(iv)</strong> The Practitioner shall at all the times while
            rendering / availing Services comply with all Applicable Laws,
            including but not limited to The Indian Medical Council
            (Professional Conduct, Etiquette and Ethics) Regulations, 2002 and
            any other laws, rules and regulations that regulate the practice of
            medicine in India.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="paragraphAlignment"
          >
            <strong>(v)</strong> The Practitioner shall provide his/her Services
            to the Users in accordance with the highest standards of
            professional ethics and practice as may from time to time be
            applicable to and in the best practices, in the fields of medicine
            in India.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="paragraphAlignment"
          >
            <strong>(vi)</strong> The Practitioner will provide consultation
            only in systems of medicine in which he/she is duly qualified,
            trained, experienced and licensed to practice.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="paragraphAlignment"
          >
            <strong>(vii)</strong> The Practitioner will immediately intimate
            DOC and shall immediately stop using/ rendering the Services on
            Website if the Practitioner is at any time disqualified / questioned
            / investigated for practicing medicine in India as per the
            Applicable Laws.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="paragraphAlignment"
          >
            <strong>(viii)</strong> The Company assigns its Practitioners
            through the system’s algorithm/software-program, which identifies
            the most relevant Practitioner. In some cases, the Users can choose
            the Practitioner of choice through the search options made available
            on the Website. The Company also permits the User to shuffle /
            change the Practitioner in case the User believes that the
            Practitioner allotted is not suitable for advising / treating Users
            / patients.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="paragraphAlignment"
          >
            <strong>(ix)</strong> In case any prescription is being provided by
            the Practitioner to the User through website, the same is being
            provided strictly basis the online consultation and the information
            / data given by the User and the Practitioner has not personally
            checked and analyzed the health and condition of the User.
            Prescription provided by the Practitioner may vary when examined in
            person, hence, Practitioner shall clearly intimate to the User that
            in no event shall the prescription provided by Practitioners may be
            relied upon as a final and conclusive solution in case of any
            adverse health condition or adverse reactions.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="paragraphAlignment"
          >
            <strong>(x)</strong> Under any case and at any time, if basis the
            Practitioner’s best judgement, Practitioner feels that the User
            needs to consult the doctor in person and the teleconsultation won’t
            be the best method for the treatment, then Practitioner shall advise
            so to the User without any delay and intimate the same to the DOC.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="paragraphAlignment"
          >
            <strong>(xi)</strong> The Practitioner shall provide Services to the
            Users on the Website subject to: (a) an ongoing treatment with their
            medical practitioner; (b) a condition which does not require
            emergency treatment, physical examination or medical attention; (c)
            medical history available as records with them for reference; (d) a
            record of physical examination and report thereof with them,
            generated through their local medical practitioner; and (e)
            consultation with their medical practitioner before abandoning or
            modifying their ongoing treatment.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="paragraphAlignment"
          >
            <strong>(xii)</strong>During the consultation and thereafter, the
            Practitioner shall fill/upload the prescription/health records of
            the User on the account of the User on the Website for the easy and
            safe access by the User. These records will also be made available
            to the Company’s medical team for coordination purposes, follow up
            process, carrying out of a regular audit of the Practitioners for
            the purpose of improving treatment quality, user experience, and
            other related processes.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="paragraphAlignment"
          >
            <strong>(xiii)</strong> The Practitioner acknowledges and confirms
            that they shall not use the data on the Website which may include
            texts, messages, photographs, reports, audio or video recordings or
            any other material exchanged between the User and the Practitioner
            which could inter alia include User's personal information,
            including sensitive personal information for any purpose other than
            elaborated in this Agreement. This personal information will be
            processed in accordance with Privacy Policy.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="paragraphAlignment"
          >
            <strong>(xiv)</strong> The Practitioner shall not use abusive,
            forced, or coercive language with the User. In the event of an abuse
            from the Practitioner is reported by a User, DOC reserves the right
            to terminate the Services and restrict the access and use of Website
            by the Practitioner in future. DOC is not responsible for honoring
            any refund request towards his/her consultation paid to DOC. DOC
            shall not be responsible for the payment of any due consultation fee
            and charges to such Practitioner and shall have the right to claim
            for any compensation as may be demanded by the User for wrong /
            abusive / unsatisfactory consultation.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="paragraphAlignment"
          >
            <strong>(xv)</strong> The Practitioner shall not prescribe drugs
            (including higher dose strength) that do not conform to the
            regulations under the Telemedicine Guidelines.
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            className="paragraphAlignment"
          >
            <strong>(xvi)</strong> The Practitioner shall not copy, store, or
            save any data from the Website of DOC or the User on its personal
            computer or on any external device. The Practitioner shall only use
            such data and storage
          </Typography>
        </div>
        <Typography variant="h6" gutterBottom className="heading">
          7.&nbsp;&nbsp;DISCLAIMER OF WARRANTIES/LIMITATION OF LIABILITY
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>7.1</strong> DOC has endeavored to ensure that all the
          information on the Website is correct, but DOC neither warrants nor
          makes any representations regarding the quality, accuracy or
          completeness of any data or information contained. DOC makes no
          warranty of any kind, express, implied, statutory or otherwise,
          concerning the Website and/or its contents and disclaims any and all
          implied or express warranties of fitness for a particular purpose to
          the maximum extent permitted by Applicable Laws and warranties of
          merchantability in respect of Services, including any liability,
          responsibility or any other claim, whatsoever, in respect of any loss,
          whether direct or consequential, to any user or any other person,
          arising out of or from the use of the information contained on the
          Website. No advice or information, whether oral or written, obtained
          by you from the Website or Services shall create any warranty not
          expressly stated in the Terms.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>7.2</strong> Since DOC acts only as a platform for
          telemedicine services, it shall not have any liability whatsoever for
          any aspect of the arrangements / expectation of the Users as regards
          the standards and quality of Services provided by the Practitioner. In
          no circumstances shall DOC be liable for the Services and quality of
          the Services provided by the Practitioner.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>7.3</strong> DOC enrolls and permit the access of Website to
          the Practitioners after verification and background check, however in
          case of any forge documentation or fraud committed by the
          Practitioner, DOC takes no liability or responsibility of the same.
          Further DOC does not endorse any specific Practitioner or advertiser
          on its Website in any manner. The Users are requested to verify the
          accuracy of all information on their own before undertaking any
          reliance on such information.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>7.4</strong> The Company, its associates and technology
          partners make no representations or warranties about the accuracy,
          reliability, completeness, correctness and/or timeliness of any
          content, information, software, text, graphics, links or
          communications provided on or through the use of the Website or that
          the operation of the Website will be error-free and/or uninterrupted.
          Consequently, the Company takes no liability whatsoever for any
          monetary or other damage suffered by the User on account of the delay,
          failure, interruption, or corruption of any data or other information
          transmitted in connection with use of the Website; and/or any
          interruption or errors in the operation of the Website.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>7.5</strong> Users agree that the DOC shall have no
          responsibility for any losses/damages suffered in connection with the
          use of this Website or any content contained therein, or any
          loss/damage suffered in the event of default or misrepresentation by
          any other user of this Website.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>7.6</strong> Practitioner represents that they have not ever
          been accused of any fraud, misrepresentation, or default in repayment
          of any claims or monies to any third party whether a financial
          institution or not. Practitioner also represent that there are no
          outstanding disputes in connection with your moral turpitude or
          financial status.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>7.7</strong> The Practitioner shall be solely responsible for
          compliance with all requirements applicable to the medical services
          that the Practitioner provides to the Users and will be solely liable
          for all medical consultation, medical advice, diagnosis or treatment.
          DOC shall not be responsible or liable for any defect or deficiency in
          the medical consultation, diagnosis or treatment provided by the
          Practitioner and the Practitioner agrees to indemnify and hold
          harmless DOC and its directors, officers and employees, against all
          loss, settlement, costs or expenses (including legal fees), incurred
          resulting from or arising out of any claims of medical negligence or
          failure to comply with any Applicable Law in relation to the medical
          consultation provided by the Practitioner.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>7.8</strong> In no event shall DOC be liable for any losses
          including direct, indirect, punitive, exemplary, incidental, special,
          consequential damages or any other damages resulting from: (a) the use
          or the inability to use the Services; (b) the cost of procurement of
          substitute Services or resulting from any goods, information or
          services purchased or obtained or messages received or transactions
          entered into on the Website; (c) unauthorized access to or alteration
          of the Practitioner’s transmissions or data; (d) any other matter
          relating to the Services including, without limitation, damages for
          loss of use, data or profits, arising out of or in any way connected
          with the use or performance of the Website/Services. Neither shall DOC
          be responsible for the delay or inability to use the Website/Services,
          the provision of or failure to provide Services, or for any
          information, data, software, products, services, and related graphics
          obtained through the Website, or otherwise arising out of the use of
          the Website, whether based on contract, tort, negligence, strict
          liability or otherwise. Further, DOC shall not be held responsible for
          non-availability of the Website or use of the Website during periodic
          maintenance operations or any unplanned suspension of access to the
          Website that may occur due to technical reasons or for any reason
          beyond DOC’s control. The Practitioner understands and agrees that any
          material and/or data downloaded or otherwise obtained through the
          Website is done entirely at their own discretion and risk and they
          will be solely responsible for any damage to their computer systems or
          loss of data that results from the download of such material and/or
          data. These limitations, disclaimer of warranties and exclusions apply
          without regard to whether the damages arise from (a) breach of
          contract, (b) breach of warranty, (c) negligence, or (d) any other
          cause of action, to the extent such exclusion and limitations are not
          prohibited by applicable law. Under all or any circumstances, which
          may be any or whatsoever, the maximum liability on part of DOC, in
          respect of any Services offered on the Website, shall be limited to
          the refund of total amount received by DOC from such transaction less
          any cancellation, refund or others charges, as may be applicable. In
          no case the liability shall include any consequential loss, damage or
          additional expense whatsoever.
        </Typography>
        <Typography variant="h6" gutterBottom className="heading">
          8.&nbsp;&nbsp;INDEMNITY
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          The Practitioner and the User shall indemnify and keep indemnified and
          hold harmless the Company and its affiliates, subsidiaries, directors,
          officers, employees and agents, directly and indirectly, from and
          against any and all claims, proceedings, penalties, damages, loss,
          liability, actions, costs and expenses (including but not limited to
          court fees and attorney fees) arising due to or in relation to the use
          of Website by the Practitioner / User, by breach of the Terms or
          violation of any Applicable Law, rules or regulations by the
          Practitioner, or due to such other actions, omissions or commissions
          of the Practitioner / User that gave rise to the claim.
        </Typography>
        <Typography variant="h6" gutterBottom className="heading">
          9.&nbsp;&nbsp;INTELLECTUAL PROPERTY
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          The Practitioner and the User acknowledges and agrees that all
          intellectual property rights in relation to the Website and/or the
          Services vests with DOC. The Practitioner / User agrees not to
          infringe upon DOC’s intellectual property by copying or plagiarizing
          content on the Website. DOC reserves its right to initiate all
          necessary legal remedies available to them in case of such an
          infringement by the Practitioner / User. The Practitioner / User
          agrees not to post any comment / content that would violate the
          intellectual property of any third party, including but not limited to
          patent, trademark, copyright, or other proprietary rights. DOC
          reserves the right to remove any comments / content which it may
          determine at its own discretion as violating the rights / sentiments /
          intellectual property rights of DOC and/ or any third party. The
          Practitioner / User agrees to absolve DOC from and indefinitely
          indemnify DOC against all claims that may arise as a result of any
          third-party intellectual property right claim that may arise from the
          any breach of intellectual property.
        </Typography>
        <Typography variant="h6" gutterBottom className="heading">
          10.&nbsp;&nbsp;TERMINATION/ ACCESS RESTRICTION
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          DOC reserves the right, in its sole discretion, to terminate the
          access of the Practitioner / User to the Website and the related
          Services or any portion thereof at any time, without notice.
        </Typography>
        <Typography variant="h6" gutterBottom className="heading">
          11.&nbsp;&nbsp;RELATIONSHIP
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          None of the provisions of these Terms, Privacy Policy and the
          Agreement, notices or the right to use the Website by the Practitioner
          contained herein or any other section or pages of the Website and/or
          the linked sites, shall be deemed to constitute a partnership between
          the Practitioner / User and DOC and no party shall have any authority
          to bind or shall be deemed to be the agent of the other in any way.
        </Typography>
        <Typography variant="h6" gutterBottom className="heading">
          12.&nbsp;&nbsp;SEVERABILITY
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          If any provision of the Agreement is determined to be invalid or
          unenforceable in whole or in part, such invalidity or unenforceability
          shall attach only to such provision or part of such provision and the
          remaining part of such provision and all other provisions shall
          continue to be in full force and effect. If any provision of the
          Agreement needs to be replaced, interpreted or supplemented, this
          shall be done in a manner that as far as possible preserves the
          spirit, content and purpose of the Agreement.
        </Typography>
        <Typography variant="h6" gutterBottom className="heading">
          13.&nbsp;&nbsp;TERM AND TERMINATION OF AGREEMENT AND SERVICES
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>13.1</strong> The Agreement is effective unless and until
          terminated by either the Practitioner or User or DOC in writing.
          Either the Practitioner or DOC may terminate the Agreement with or
          without cause at any time to be effective immediately, provided that
          the Practitioner discontinues any further use of the Website. However,
          merely a non-usage of Website shall not release the Practitioner /
          User from their Liability and Indemnity towards the Company. In case
          Practitioner / User wishes to discontinue / terminate the Agreement
          with the Company, Practitioner / User shall be required to serve
          written notice of the intent therein to the DOC, and thereafter,
          subject to remaining applicability and survival of Indemnity,
          Liability, Confidentiality, and other provisions which ought to
          survive the termination, the Agreement shall stand terminated on the
          acknowledgement and confirmation of DOC.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>13.2</strong> The Practitioner acknowledges and agrees that
          DOC may under certain circumstances and without the pre-condition or
          requirement of any prior notice, immediately terminate the
          Practitioner’s user id and access to the Website/Services and such
          termination shall be without any liability upon the Website and/ or
          the DOC. Causes for termination may include, but shall not be limited
          to, breach by the Practitioner of the Agreement, requests by
          enforcement or government agencies, requests by the Users, non-payment
          of fees owed by the Practitioner in connection with the Services as
          specified in the applicable Terms. Further, DOC shall also be not
          liable to provide any reason to the Practitioner for the termination
          of the Agreement and Services thereof.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>13.3</strong> The Agreement may be terminated by either the
          Practitioner or DOC through a written notice to the other. DOC shall
          not be liable to the Practitioner or any third party for termination
          of any Service. Should the Practitioner object to any terms and
          conditions of the Terms, Privacy Policy and the Agreement or become
          dissatisfied with the Services in any way, the Practitioner’s only
          recourse is to immediately: (a) discontinue use of the
          Website/Service; and (b) immediately notify DOC of such discontinuance
          in writing.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>13.4</strong> Upon termination of the Service, Practitioner’s
          right to use the Website/Services and software shall immediately
          cease. The Practitioner shall have no right and DOC shall have no
          obligation thereafter to execute any of the Practitioner’s incomplete
          tasks or forward any unread or unsent messages to the User or any
          third party. Once the Practitioner’s registration or the Services are
          terminated, cancelled or suspended, any data that the Practitioner has
          stored on the Website may not be retrieved later. Upon the termination
          of the Agreement by either the Practitioner and/ or DOC, the
          Practitioner must promptly destroy all materials downloaded or
          otherwise obtained from the Website, as well as all copies of such
          materials, whether made under the Agreement or otherwise.
        </Typography>
        <Typography variant="h6" gutterBottom className="heading">
          14.&nbsp;&nbsp;NOTICES
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>14.1</strong> All notices and communications shall be in
          writing, in English and shall deemed given if delivered personally or
          by commercial messenger or courier service, or mailed by registered or
          certified mail (return receipt requested) or sent via email (with
          acknowledgment of complete transmission) to the following address:
          <br />
          <br /> If to DOC, at 5th floor, SAS Tower, Sector 38, Gurugram 122001
          Haryana, or at the address posted on the Website.
          <br />
          <br />
          If to the Practitioner, at the communication and/or email address
          specified in the application form availing of a DOC Service.
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          <strong>14.2</strong>For the purposes of this Clause, a delivery shall
          be deemed to be effective: (a) in the case of registered mail or
          courier, when delivered to the recipient or 2 (two) business days
          after dispatch, whichever is earlier, (b) in the case of personal
          delivery, at the time of delivery, and (c) in case of e-mail, at the
          time when it is sent (provided no message of non-delivery / failed
          delivery is received by the sender). If any notice/ communication is
          received or deemed to have been received on a day which is not a
          business day or after the working hours of the recipient on a business
          day, it shall be deemed to have been received on the next business
          day. Any notice/ communication to be given or made by the parties
          hereto shall be in the manner as provided for herein above, at the
          address as provided for herein.
        </Typography>
        <Typography variant="h6" gutterBottom className="heading">
          15.&nbsp;&nbsp;GOVERNING LAW AND JURISDICTION
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          The Agreement shall be governed by and constructed in accordance with
          the laws of India without reference to conflict of laws principles and
          disputes arising in relation hereto shall be subject to the exclusive
          jurisdiction of the courts of [New Delhi, India].
        </Typography>
        <Typography variant="h6" gutterBottom className="heading">
          16.&nbsp;&nbsp;ENTIRE AGREEMENT AND WAIVER
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          The Agreement constitutes the entire agreement between DOC and the
          Practitioner / User and supersede all prior agreements and
          understanding, oral and written and if any part of the Agreement is
          determined to be invalid or unenforceable under applicable law, then
          the invalid or unenforceable provision will be deemed to be superseded
          by a valid, enforceable provision that most closely matches the intent
          of the original provision and the remainder of the Agreement shall
          continue in effect. The DOC’s failure to act concerning a breach by
          the Practitioner / User(s) or others does not waive its right to act
          concerning subsequent or similar breaches.
        </Typography>
        <Typography variant="h6" gutterBottom className="heading">
          17.&nbsp;&nbsp;FORCE MAJEURE
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          The DOC shall not be liable for failure to perform any of its
          obligations if such failure is as a result of acts of God (including
          fire, flood, earthquake, storm, hurricane, epidemic, pandemic or other
          natural disasters), war, invasion, an act of foreign enemies, civil
          war, rebellion, revolution, insurrection, military or usurped power or
          confiscation, terrorist activities, nationalisation, government
          sanction, blockage, embargo, labour dispute, strike, lockout, robbery,
          theft of data or any interruption or any failure of electricity or
          server, system, computer, internet or telephone service or any other
          act beyond the control of the DOC.
        </Typography>
        <Typography variant="h6" gutterBottom className="heading">
          18.&nbsp;&nbsp;INFORMATION GATHERED AND TRACKED
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          By accessing this Website, the Practitioner / User hereby agrees and
          understands that the Practitioner’s / User’s internet protocol (“IP”)
          address, device used to access the Website and other demographics will
          be logged and recorded. If the Practitioner / User chooses to register
          and create a profile on the Website, DOC will collect and store such
          information (including personal information) as specified above. All
          information collected from the Practitioner / User, including
          information submitted for the registration of a profile, is subject to
          the Privacy Policy. DOC may collect further information from the
          Practitioner / User if he/she wishes to receive certain services
          available on the Website, which information will be requested for at
          the time the Practitioner / User chooses to avail of such Services.
          DOC guarantees that personal information about Practitioner / User
          will not be provided to any third party without the Practitioner’s /
          User’s consent.
        </Typography>
        <Typography variant="h6" gutterBottom className="heading">
          19.&nbsp;&nbsp;CUSTOMER COMPLAINT DETAILS
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className="paragraphAlignment"
        >
          If any Practitioner / User has any grievances, questions, comments and
          requests regarding the Agreement, the Practitioner / User should
          address it to the Company at care@aiqahealth.com. Please also contact
          DOC at the address mentioned in point 14.1 above if the Practitioner
          would like to update or amend any of the information which the
          Practitioner has sent us. Please report any abuse or violations of the
          Agreement to DOC by emailing at <strong>care@aiqahealth.com.</strong>
        </Typography>
      </Container>
    </div>
  );
};

export default index;
