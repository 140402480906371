import React from "react";

const Footer = () => {
  return (
    <div style={{ position: "relative", left: "0", bottom: "0", right: "0" }}>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-12 col-xxl-12 ">
              <div className="footer-col-one text-start">
                <a
                  className="navbar-brand"
                  href="/"
                  onClick={() => localStorage.removeItem("buyNowSession")}
                >
                  <img
                    onClick={() => {}}
                    src={require("../assets/images/aiqadoc_app2.png")}
                    style={{ cursor: "pointer", width: "80px", height: "70px" }}
                    alt=""
                  />
                </a>
                <ul
                  className="d-none d-md-block d-lg-block d-xl-block d-xxl-block"
                  style={{ display: "flex" }}
                >
                  <li>
                    <i className="fas fa-solid fa-phone text-white fa-lg fa-rotate-90" />
                    <span className="mx-2">+91 6262306306</span>
                  </li>
                  <li>
                    <i className="fas fa-solid fa-envelope text-white fa-lg" />
                    <span className="mx-2">care@aiqahealth.com</span>
                  </li>
                  <li>
                    <i className="fas fa-map-marker-alt text-white fa-lg" />
                    <span className="mx-2">
                      Hospido private limited, 5th floor, tower b, sas tower,
                      sector 38, gurugram, haryana 122001
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <div className="footer-col-two ps-0 ps-md-5 ps-lg-5 ps-xl-5 ps-xxl-5">
                <h2>useful links</h2>
                <ul>
                  <li>
                    <a href="/#home">home</a>
                  </li>
                  <li>
                    <a
                      href="/#download"
                      style={{ color: "white", cursor: "pointer" }}
                    >
                      downloads
                    </a>
                  </li>
                  <li>
                    <a href="/#whyChooseSection">Why We?</a>
                  </li>
                </ul>
              </div>
            </div> */}
            {/* <div className="col-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <div className="footer-col-three ps-0 ps-md-5 ps-lg-5 ps-xl-5 ps-xxl-5">
                <h2>important links</h2>
                <ul>
                  <li>
                    <a href="#">how to reach</a>
                  </li>
                  <li>
                    <a target="_blank" href="/terms">
                      privacy policy
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="/terms">
                      terms and conditions
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="#">
                      customer grievances policy
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-block d-md-none d-lg-none d-xl-none d-xxl-none">
              <div className="footer-col-one pe-5">
                {/* <img src={require('../assets/images/logo.png')} alt="" /> */}
                <ul>
                  <li>
                    <i className="fas fa-solid fa-phone text-white fa-lg fa-rotate-90" />
                    <span className="mx-2">+91 91055 56666</span>
                  </li>
                  <li>
                    <i className="fas fa-solid fa-envelope text-white fa-lg" />
                    <span className="mx-2">care@aiqahealth.com</span>
                  </li>
                  <li>
                    <i className="fas fa-map-marker-alt text-white fa-lg" />
                    <span className="mx-2">
                      Hospido private limited, 5th floor, tower b, sas tower,
                      sector 38, gurugram, haryana 122001
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-md-3 col-lg-3 col-xl-3 col-xxl-3">
              <div className="footer-col-four pe-0 pe-md-5 pe-lg-5 pe-xl-5 pe-xxl-5">
                <h2>follow us on</h2>
                <ul className="d-flex">
                  <li>
                    <a href="https://fb.watch/aAJb6ruaIo/" target="_blank">
                      <i className="fab fa-facebook text-white fa-lg" />
                    </a>
                  </li>
                  <li className="pe-0">
                    <a
                      href="https://www.linkedin.com/posts/doctoroncallindia_commoncold-cough-covidguidelines-activity-6888345117518913536-Js9h"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin text-white fa-lg" />
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
      <div className="container-fluid" style={{ background: "#F9F9F9" }}>
        <div className=" text-center">
          <p
            style={{ lineHeight: "36px", padding: "14px 0" }}
            className="mb-0 fs-16 fw-5"
          >
            made with love in India
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
