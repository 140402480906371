import React from "react";
import Home from "./container/Home";
import { Route, Routes, Navigate } from "react-router-dom";

const App = () => {
  return (
    <>
      <Routes>
        <Route index element={<Navigate to="/privacy-policy" replace />} />

        <Route exact path="/privacy-policy" element={<Home />} />
        {/* <Route exact path="/terms" element={<Term />} /> */}
      </Routes>
    </>
  );
};

export default App;
